
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

@Component({
  name: 'member',
  components: {
    swiper,
    swiperSlide
  },
  mixins: [GlobalMixin]
})
export default class member extends Vue {
  @Provide() public list: any = []
  @Provide() public privilegeList: any = []
  @Provide() public info: any = {}
  @Provide() public idxIndex: number = 0
  @Provide() public countPerGroup: number = 4
  privilege: any = []

  public created () {
    this.refresh()
  }

  private refresh () {
    this.levelList()
  }

  @Emit()
  private async levelList () {
    try {
      const res = await httpHelper.get({
        url: 'IUser/Member',
        type: 'apiv5'
      })
      this.info = res.data
      this.list = res.data.levelList
      this.privilegeList = res.data.privilegeList
      let index = 0
      const privilegeArray = []
      const privilegeList = res.data.privilegeList || []
      while (index < privilegeList.length) {
        privilegeArray.push(res.data.privilegeList.slice(index, index += this.countPerGroup))
      }
      this.privilege = privilegeArray
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  get swiper () {
    return (this.$refs.mySwiper as any).swiper
  }

  get width () {
    const spans = this.list.length - 1
    const CurrentTotalGrowth = this.info.currentTotalGrowth
    const CurrentLevel = this.info.currentLevel
    let currentIndex = 0
    let floorGrowth = 0
    let TopGrowth = 0
    this.list.forEach((item: any, index: number) => {
      if (CurrentLevel === item.levelSysNo) {
        currentIndex = index
        floorGrowth = item.levelSysNo
        TopGrowth = item.topGrowth
      }
    })
    const width = 'width:' + (100 - ((100 / spans) * currentIndex)) + '%;'
    const longwidth = 'width:' + (100 - ((100 / spans) * currentIndex + 100 / spans * ((CurrentTotalGrowth - floorGrowth) / (TopGrowth - floorGrowth)))) + '%;'
    if (CurrentTotalGrowth - floorGrowth === 0 || TopGrowth - floorGrowth === 0) {
      return width
    } else {
      return longwidth
    }
  }

  private slideChange () {
    this.idxIndex = this.swiper.realIndex
  }

  public async addSale (item:any) {
    if (item.totalCount - item.usedCount < 1 || item.dateTick > 0) {
      return
    }
    try {
      const res = await httpHelper.post({
        url: 'IUser/RankPrivilege',
        type: 'apiv5',
        data: {
          privilegeSysNo: item.privilegeSysNo
        }
      })
      this.$toasted.show('领取成功')
      this.refresh()
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  // public privilege (privilegeList:any, countPerGroup:any) {
  //   let index = 0
  //   const privilegeArray = []
  //   while (index < privilegeList.length) {
  //     privilegeArray.push(privilegeList.slice(index, index += countPerGroup))
  //   }
  //   return privilegeArray
  // }

  @Emit()
  private goBack () {
    window.history.back()
  }
}

